import React from 'react';

export default function IndexSection__structures20() {
    return (
        <React.Fragment>
            <>
                <section className="bg-coolGray-50 py-10">  <div className="container px-4 mx-auto">    <div className="max-w-4xl mx-auto text-center">      <a id="About">        <h2 className="mb-4 text-3xl md:text-5xl leading-tight text-darkCoolGray-900 font-bold tracking-tighter">Welcome to Open Gippsland</h2>    </a>      <p className="mb-6 text-lg md:text-xl font-heading font-medium text-coolGray-500">Open Gippsland in Leongatha, Victoria, is a hidden speakeasy-style lounge bar specialising in Gippsland wines, spirits, beers and food. Enter through a discreet back entrance and leave the outside world behind. Enjoy our share plate menu featuring locally and ethically sourced ingredients in a cozy, on-trend setting crafted from local timbers and reclaimed materials.</p>  </div></div></section>


            </>
        </React.Fragment>
    );
}

