import React from 'react';

export default function IndexSection__structures25() {
    return (
        <React.Fragment>
            <>
                <section className="bg-white py-10">  <div className="container px-4 mx-auto">    <div className="max-w-4xl mx-auto text-center">      <a id="Menu">        <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-green-500 bg-green-100 font-medium uppercase rounded-full shadow-sm">Menu</span>        <h2 className="mb-4 text-3xl md:text-4xl font-heading font-bold">From The Kitchen</h2>    </a>      <p className="mb-6 text-lg md:text-xl font-heading font-medium text-coolGray-500">At Open Gippsland, our menu is a celebration of the region's finest produce. We prioritise locally and ethically sourced ingredients, ensuring every dish reflects the best Gippsland has to offer. With a strong commitment to regenerative farming practices and sustainable food sourcing, our menu changes frequently, sometimes daily. Join us to discover new and delicious flavours crafted with care and passion.</p>      <a className="inline-block py-5 px-7 text-base md:text-lg leading-4 text-green-50 font-medium text-center bg-green-500 hover:bg-red-500 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-primary rounded-md shadow-sm" href="https://www.canva.com/design/DAGP3l9Hzwg/5NpziySp540SCm_gp6nckw/view">See Our Current Menu</a>      <div className="pt-2">Open Gippsland Menu by Shalbe Welsh</div>  </div></div></section>


            </>
        </React.Fragment>
    );
}

