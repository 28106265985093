import React from 'react';

export default function IndexSection__structures24() {
    return (
        <React.Fragment>
            <>
                <section className="pb-12 bg-coolGray-50">  <div className="container mx-auto">    <div className="flex flex-wrap -mx-4">      <div className="w-full lg:w-1/2 p-4">        <img className="w-full h-80 object-cover rounded-lg" src="https://static.shuffle.dev/uploads/files/6b/6ba62887534b23dcab5be001c05685388ea17c46/A7300509.jpg" alt />    </div>      <div className="w-full lg:w-1/2 p-4">        <img className="w-full h-80 object-cover rounded-lg" src="https://static.shuffle.dev/uploads/files/6b/6ba62887534b23dcab5be001c05685388ea17c46/A7300401.jpg" alt />    </div>      <div className="w-full md:w-1/3 p-4">        <img className="w-full h-80 object-cover rounded-lg" src="https://static.shuffle.dev/uploads/files/6b/6ba62887534b23dcab5be001c05685388ea17c46/A7300494.jpg" alt />    </div>      <div className="w-full md:w-1/3 p-4">        <img className="w-full h-80 object-cover rounded-lg" src="https://static.shuffle.dev/uploads/files/6b/6ba62887534b23dcab5be001c05685388ea17c46/A7300432.jpg" alt />    </div>      <div className="w-full md:w-1/3 p-4">        <img className="w-full h-80 object-cover rounded-lg" src="https://static.shuffle.dev/uploads/files/6b/6ba62887534b23dcab5be001c05685388ea17c46/A7300383.jpg" alt />    </div>  </div></div></section>


            </>
        </React.Fragment>
    );
}

