import React from 'react';

export default function IndexSection__structures2() {
    return (
        <React.Fragment>
            <>
                <section className="relative py-32"><img className="absolute top-0 left-0 h-full w-full object-cover" src="https://static.shuffle.dev/uploads/files/6b/6ba62887534b23dcab5be001c05685388ea17c46/open-gippsland-bar.png" alt /><div className="absolute top-0 left-0 h-full w-1/2" />
  <div className="relative container px-4 mx-auto">
    <div className="max-w-md sm:max-w-xl mx-auto md:mx-0">
      <h1 className="mb-6 text-3xl md:text-5xl lg:text-6xl leading-tight text-white font-bold tracking-tighter">Lounge Bar @Open Gippsland</h1>
      <p className="leading-8 text-white pb-10 text-xl font-bold">Discover, Taste, Learn </p>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

